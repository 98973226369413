import React from 'react'
import * as Leaflet from 'leaflet'

import NewMap, { NewMapProps } from 'compositions/map/NewMap/NewMap'
import MapContent, { MapContentProps } from 'compositions/map/MapContent/MapContent'

import { Style } from 'components/dataDisplay'

import bgImage from './images/shadow.svg'


export type MapProps = MapContentProps & {
  className?: string
  center?: NewMapProps['center']
  zoom?: number
  locateUser?: NewMapProps['locateUser']
  searchField?: NewMapProps['searchField']
  withFullScreen?: boolean
  withShortAttribution?: boolean
  withUserGeolocation?: boolean
  withMapControls?: boolean
  editable?: boolean
}

const Map: React.FC<MapProps> = (props) => {
  const {
    className,
    polylines = [],
    placemarks = [],
    multiPolygons = [],
    center,
    withFullScreen = true,
    searchField,
    locateUser,
    withUserGeolocation = false,
    withShortAttribution = false,
    withMapControls = true,
    children,
  } = props

  return (
    <NewMap
      className={className}
      searchField={searchField}
      center={center}
      locateUser={locateUser}
      withFullScreen={withFullScreen}
      withUserGeolocation={withUserGeolocation}
      withShortAttribution={withShortAttribution}
      withMapControls={withMapControls}
    >
      <MapContent
        polylines={polylines}
        placemarks={placemarks}
        multiPolygons={multiPolygons}
      />
      <Style
        rules={[
          {
            selectors: [ '.ymaps-2-1-79-image::before' ],
            bgImage,
          },
        ]}
      />
      {children}
    </NewMap>
  )
}


export default Map
