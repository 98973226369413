import cx from 'classnames'
import { useMap } from 'react-leaflet'
import React, { useCallback, useEffect, useRef } from 'react'

import { LinkButton } from 'components/inputs'


const ExpandButton = (props) => {
  const { expandLinkTitle, onExpandLinkClick } = props

  const map = useMap()

  const ref = useRef<HTMLDivElement>()

  const handleExpandClick = useCallback(() => {
    onExpandLinkClick()
    setTimeout(() => map.invalidateSize())
  }, [ onExpandLinkClick, map ])

  useEffect(() => {
    map.expandButtonRef = ref
  }, [ map ])

  return (
    <div ref={ref} className={cx('absolute flex w-full justify-center z-1001')} style={{ bottom: '0px' }}>
      <LinkButton
        title={expandLinkTitle}
        onClick={handleExpandClick}
      />
    </div>
  )
}

export default ExpandButton