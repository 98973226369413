import L from 'leaflet'
import cx from 'classnames'
import React, { useCallback, useState } from 'react'
import MarkerClusterGroup from 'react-leaflet-markercluster'

import Popup from '../Popup/Popup'

import s from './MarkerCluster.scss'


const MarkerCluster = (props) => {
  const {
    children,
    showCoverageOnHover,
    spiderfyOnMaxZoom,
    maxClusterRadius = 30,
    disableClusteringAtZoom = 19,
    getAllChildren,
  } = props

  const [ state, setState ] = useState({ position: null, data: [] })
  const { position, data } = state

  const [ zoomToBoundsOnClick, setZoomToBoundsOnClick ] = useState<boolean>(true)

  const onClusterClick = useCallback((event) => {
    const markers = typeof getAllChildren === 'function' ? getAllChildren(event.sourceTarget) : event.layer.getAllChildMarkers()

    const isMarkersHaveTheSameCoords = (markers) => {
      for (let i = 1; i < markers.length; i++) {
        const marker = markers[i]
        const prevMarker = markers[i - 1]

        if (marker._latlng.lat.toFixed(3) !== prevMarker._latlng.lat.toFixed(3)
         || marker._latlng.lng.toFixed(3) !== prevMarker._latlng.lng.toFixed(3)) {
          return false
        }
      }

      return true
    }

    if (markers.length > 1 && isMarkersHaveTheSameCoords(markers)) {
      setZoomToBoundsOnClick(false)
      setState({
        position: event.latlng,
        data: markers.map(({ options }) => options?.children?.props?.data),
      })
    }
    setZoomToBoundsOnClick(true)
  }, [ getAllChildren ])

  const createClusterIcon = useCallback((cluster) => {
    const childCount = typeof getAllChildren === 'function' ? getAllChildren(cluster).length : cluster.getChildCount()

    const clusterClassName = cx(s.cluster, 'bg-rocky color-white text-h24 flex items-center justify-center radius-100')

    return new L.DivIcon({
      html: `<div class="${clusterClassName}">${childCount}</div>`,
      className: cx('marker-cluster', `cluster_${cluster._leaflet_id}`),
      iconSize: new L.Point(64, 64), // TODO make different sizes for large clusters?
    })
  }, [ getAllChildren ])

  return (
    <>
      <MarkerClusterGroup
        iconCreateFunction={createClusterIcon}
        maxClusterRadius={maxClusterRadius}
        spiderfyOnMaxZoom={spiderfyOnMaxZoom}
        showCoverageOnHover={showCoverageOnHover}
        zoomToBoundsOnClick={zoomToBoundsOnClick}
        disableClusteringAtZoom={disableClusteringAtZoom}
        animate
        eventHandlers={{
          'clusterclick': onClusterClick,
        }}
      >
        {children}
      </MarkerClusterGroup>
      {
        Boolean(position && data.length) && (
          <Popup
            className={s.popup}
            position={position}
            data={data}
            onClose={() => setState({ position: null, data: null })}
          />
        )
      }
    </>
  )
}


export default React.memo(MarkerCluster)
