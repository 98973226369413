import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import Map, { MapProps } from 'compositions/map/Map/Map'

import { ExpandButton } from './components'


export type ExpandableMapProps = MapProps & {
  isExpanded: boolean
  expandLinkTitle?: string
  withUserGeolocation?: boolean
  onExpandLinkClick?: () => void
}

const ExpandableMap: React.FC<ExpandableMapProps> = (props) => {
  const {
    className,
    polylines, placemarks, multiPolygons,
    isExpanded, expandLinkTitle, searchField, onExpandLinkClick,
    withUserGeolocation = false, children, editable,
  } = props

  const { isDesktop, isMobile, isTablet } = useDevice()

  const mobileHeight = isExpanded ? 312 + 56 : 144 + 56
  const tabletHeight = isExpanded ? 536 + 56 : 112 + 56
  const mapHeight = isMobile ? mobileHeight : isTablet ? tabletHeight : 648

  return (
    <div>
      <div className={cx(className, 'relative')} style={{ height: `${mapHeight}px` }}>
        <Map
          className="radius-24 overflow-hidden"
          // polygons={polygons}
          polylines={polylines}
          placemarks={placemarks}
          searchField={searchField}
          multiPolygons={multiPolygons}
          withUserGeolocation={withUserGeolocation}
          withMapControls={isDesktop || isExpanded}
          withShortAttribution={isMobile || isTablet}
          editable={editable}
        >
          {children}
          {
            Boolean(expandLinkTitle) && (
              <ExpandButton
                expandLinkTitle={expandLinkTitle}
                onExpandLinkClick={onExpandLinkClick}
              />
            )
          }
        </Map>
      </div>
    </div>
  )
}


export default ExpandableMap
