import React from 'react'
import cx from 'classnames'
import { Tooltip as LeafletTooltip } from 'react-leaflet'

import s from './Tooltip.scss'


type TooltipProps = {
  className?: string
}

const Tooltip: React.FC<TooltipProps> = (props) => {
  const { className, children, ...rest } = props

  return (
    <LeafletTooltip
      className={cx(className, s.root)}
      opacity={1}
      {...rest}
    >
      {children}
    </LeafletTooltip>
  )
}

export default Tooltip